import { state } from './state';
import _ from 'lodash';

export const mutationsLocal = {
	setRegions(arr) {
		state.Regions = arr;
	},
	setCapcha(bool) {
		state.capchaState = bool;
	},
	setSearch(text) {
		state.search = text;
	},
	nextAnswer(index) {
		state.number = index;
	},
	setAnket(data) {
		state.search = '';
		state.disabledState = false;
		state.anketFull = data.questionBlocks[0].questions;
		state.anketFull.questions.sort((a, b) => a.blockNumber - b.blockNumber);
		state.dependencyQuestions = [];
		state.anketFull.forEach((question) => {
			question.answers.forEach((answer) => {
				if (answer.dependencyQuestions.length) {
					answer.dependencyQuestions.forEach((dependet) => {
						state.dependencyQuestions.push(dependet.dependencyQuestionId);
					});
				}
			});
		});
		state.dependencyQuestions = _.uniq(state.dependencyQuestions);
		console.log('"4"', state.dependencyQuestions);
		// state.anket = state.anketFull.filter(function (o) {
		// 	let delAnswer = true;
		// 	state.dependencyQuestions.forEach((item) => {
		// 		if (item === o.id) {
		// 			delAnswer = false;
		// 		}
		// 	});
		// 	return delAnswer;
		// });
		// state.anket = [{}];
		state.anketFull.forEach((element) => {
			let delAnswer = true;
			state.dependencyQuestions.forEach((item) => {
				if (item === element.id) {
					delAnswer = false;
				}
			});
			if(!delAnswer) {
				console.log('"6"');
				state.anket.push(element);
			}
		});
		console.log('"5"', state.anket);
		// Vue.set(state, 'anket', state.anket);
	},
	setMO(data) {
		if (data === false) {
			state.disabledState = false;
			state.mo = {};
		} else {
			state.mo = data;
			state.disabledState = true;
		}
	},
	setRegion(data) {
		state.Region = data;
	},
	anketCode(data) {
		state.anketCode = data;
	},
	setMoItems(arr) {
		state.moItems = [];
		arr.forEach((item) => {
			if (item[state.anketTypeName]) {
				state.moItems.push(item);
			}
		});
	},
	anketaChange(id) {
		let returnId = [];
		returnId = _.filter(state.dependencyQuestions, function (o) {
			let delAnswer = true;
			id.forEach((item) => {
				if (item === o) {
					delAnswer = false;
				}
			});
			return delAnswer;
		});
		_.uniq(returnId);
		// state.anket = _.filter(state.anketFull, function (o) {
		// 	let delAnswer = true;
		// 	returnId.forEach((item) => {
		// 		if (item === o.id) {
		// 			delAnswer = false;
		// 		}
		// 	});
		// 	return delAnswer;
		// });
	},
	changeType(param) {
		state.anketTypeName = param;
		if (!state.mo) {
			state.disabledState = true;
		} else {
			state.disabledState = false;
		}
		state.capchaState = false;
		switch (param) {
			case 'stacionar':
				state.anketType = 'ae06ab6a-8be2-47e7-a692-64f50e98fc59';
				state.title = 'Анкета для оценки качества условий оказания услуг медицинскими организациями в стационарных условиях';
				break;
			case 'ambulator':
				state.anketType = 'df1d5f57-53fd-4468-8680-04b56d94669c';
				state.title = 'Анкета для оценки качества условий оказания услуг медицинскими организациями в амбулаторных условиях';
				break;
			case 'healthResort':
				state.anketType = 'f336e95d-3c25-4f5e-9a89-dfe0ee331a50';
				state.title = 'Анкета для оценки качества условий оказания услуг медицинскими организациями в стационарных условиях (санаторно-курортные организации)';
				break;
			case 'psychiatric':
				state.anketType = '86f97b7b-7f73-4756-84bd-133515fa6b4c';
				state.title = `Анкета для оценки качества условий оказания услуг медицинскими организациями в стационарных условиях
				(психиатрические больницы, в том числе детские; психоневрологические больницы, в том числе детские)`;
				break;
			default:
				state.anketType = '';
		}
	}
};
