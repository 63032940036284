<template>
	<div class="mb-6 sm:mb-0">
		<div class="item mb-6">
			<p class="label-lg">Регион</p>
			<p class="value-lg">{{ currentRegion }}</p>
		</div>
		<div class="item">
			<p class="label-lg">Медицинская организация</p>
			<Autocomplite type="autocomplite" placeholder="Пожалуйста, выберите медицинскую организацию" />
			<div class="item mt-6" v-if="dataMO.webSiteAddress">
				<p class="label-lg">Сайт</p>
				<p class="value-lg">{{ dataMO.webSiteAddress }}</p>
			</div>
			<div class="item mt-6" v-if="dataMO.postAddress">
				<p class="label-lg" v-if="dataMO.postAddress">Фактический адрес</p>
				<p class="value-lg" v-if="dataMO.postAddress">{{ dataMO.postAddress }}</p>
			</div>
		</div>
	</div>
</template>

<script>
import { gettersLocal } from '@/views/Questionnaire/store/getters';
import { actions } from '@/views/Questionnaire/store/actions';
import Autocomplite from '@/components/Autocomplite.vue';

export default {
	name: 'ChangeRegion',
	components: { Autocomplite },
	data() {
		return {};
	},
	computed: {
		...gettersLocal
	},
	methods: {
		...actions
	},
	mounted() {}
};
</script>

<style>
.label-lg {
	@apply text-sm pb-2.5 font-bold;
	color: #a8b1ce;
}

.value-lg {
	@apply font-medium text-base;
}
</style>
